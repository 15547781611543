<template>
  <section class="wrap">
    <dl class="Nbanner">
      <dt>轻松合作让科技绽放未来</dt>
      <dd>始于专注心无旁骛</dd>
      <dd class="NBbtn"><a href="javascript:;">开启互联网新生活</a></dd>
    </dl>
  </section>
  <section class="wrap">
    <article class="menu"><img src="../assets/images/Lmenu.png" class="menuIcon">联系我们<img src="../assets/images/Rmenu.png" class="menuIcon"></article>
    <dl class="Hcontact">
      <dt class="allmap" id="allmap"></dt>
      <dd>
        <p><img src="../assets/images/logo2.png" alt="logo"></p>
          <div><em class="icon-kefu"></em>联系电话：13487621881<br><em class="icon-youxiang"></em>联系邮箱：hctechmail@163.com<br><em class="icon-weizhi"></em>地址：武汉市关山大道光谷时代广场b座</div>
      </dd>
    </dl>
    <div class="contact">
      <dl class="Tcontact">
        <dt>想联系我们?</dt>
        <dd>您的信息对我们来说非常重要</dd>
      </dl>
      <dl class="Cform">
        <dt class="icon-xingming"><input type="text" placeholder="您的姓名"></dt>
        <dt class="icon-youxiang"><input type="text" placeholder="您的邮箱地址"></dt>
        <dt><textarea placeholder="简单叙述您的需求…"></textarea></dt>
        <dd><input type="submit" value="发送留言"></dd>
      </dl>
    </div>
  </section>
</template>

<script>
export default {
  name: 'App',
  mounted(){
    this.$emit('getBgClass', "contactBg");
    var map = new BMap.Map("allmap");
    var point = new BMap.Point(114.420016,30.507505);
    var top_left_control = new BMap.ScaleControl({anchor: BMAP_ANCHOR_TOP_LEFT});
    var top_left_navigation = new BMap.NavigationControl();
    map.addControl(top_left_control);
    map.addControl(top_left_navigation);
    var marker = new BMap.Marker(point);
    map.addOverlay(marker);
    map.enableScrollWheelZoom(true);
    map.centerAndZoom(point, 13);
    var opts = {
      width : 200,     // 信息窗口宽度
      height: 72,     // 信息窗口高度
      title : "湖北汇充科技有限公司" , // 信息窗口标题
      enableMessage:true,//设置允许信息窗发送短息
      message:"亲耐滴，晚上一起吃个饭吧？戳下面的链接看下地址喔~"
    }
    var infoWindow = new BMap.InfoWindow("地址：武汉市关山大道光谷时代广场b座", opts);  // 创建信息窗口对象 
    marker.addEventListener("click", function(){          
      map.openInfoWindow(infoWindow,point); //开启信息窗口
    });
  }
}
</script>
